import {
  KSAuthLoginInitResponseSchema,
  LoginResponseSchema,
  PlatformEnum,
  UserAccessSchema,
} from '@kvika/audur-api-types';
import AudurApiError from 'api/ApiError';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { errorHandling, useAppContext } from '../../store/AppContext';
import { ActionType } from '../../store/Reducers';
import { SegmentTrackingId, identifyUser, trackEvent } from '../AudurAnalytics';
import { LocalStorageKeys, setLocalStorageItem } from '../browserStorage/LocalStorage';
import { MAX_POLLING_TIME } from '../Constants';
import { Links } from '../navigation/navigation';
import { SentryErrors, setSentryUser } from '../SentryWrapper';
import { ServerErrorCode } from '../types/APITypes';
import { LoginIntent, LoginStatusProps, LoginStep } from '../types/Types';

export const useLogin = (
  phoneNumber?: string,
  ssn?: string,
  isLoggingInWithPhoneNumber?: boolean,
  loginIntent?: LoginIntent
) => {
  /**
   *@Logging_Onboarding_as_company
   * Logging or onboarding as company will probably have a slightly different request and response schema from current API
   * for that reason @isCompany and @onboarding params are added which we will later use when API docs for company onboard and login
   * will be known
   * */

  const { state, dispatch } = useAppContext();
  const { apiClient } = state;
  const [loginState, setLoginState] = useState<LoginStep>(LoginStep.Idle);
  const [verificationCode, setVerificationCode] = useState<string>();
  const router = useRouter();
  const [changingRouteStarted, setChangingRouteStarted] = useState(false);

  const onLoginStart = () => {
    const identifier = isLoggingInWithPhoneNumber ? phoneNumber : ssn;
    if (!identifier) return;

    setLoginState(LoginStep.Started);
    setVerificationCode('');
    trackEvent({
      event: SegmentTrackingId.LoginStarted,
      properties: isLoggingInWithPhoneNumber
        ? { identifier: phoneNumber, method: SegmentTrackingId.EIDLoginMethod }
        : { identifier: ssn, method: SegmentTrackingId.ESIMLoginMethod },
    });

    apiClient
      .getAudurWebApiClient()
      .postStartLogin({
        identifier,
        platform: PlatformEnum.Web,
      })
      .then((response) => {
        if (isLoggingInWithPhoneNumber) {
          dispatch({ type: ActionType.UpdatePhoneNumber, payload: phoneNumber });
        }
        onStartLoginSuccess(response);
      })
      .catch((loginError: AudurApiError) => {
        if (
          loginError.response?.status === 409 &&
          loginError.response.data.code === ServerErrorCode.AUTH_ERROR_ALREADY_PENDING_LOGIN_REQUEST
        ) {
          setLoginState(LoginStep.Pending);
        } else {
          onLoginFailed(loginError);
        }
      });
  };

  const handleLoginSuccess = (loginResponse: LoginResponseSchema, userAccess: UserAccessSchema[]) => {
    setLocalStorageItem(LocalStorageKeys.EXTERNAL_ID, loginResponse.externalId);
    identifyUser(loginResponse.externalId);

    dispatch({ type: ActionType.UpdateAccess, payload: userAccess.find((user) => user.isCurrent)! }); // eslint-disable-line
    dispatch({ type: ActionType.UpdateLoginResponse, payload: loginResponse });
    setSentryUser(loginResponse.externalId, loginResponse.email ?? '');
    trackEvent({ event: SegmentTrackingId.LoginCompleted });

    const skipAccountSelection =
      userAccess.length === 1 && userAccess[0].isOnboarded && loginIntent === LoginIntent.individual;

    if (skipAccountSelection && !loginResponse.pepApprovalPending) {
      userAccess[0].isOnboarded ? router.push(Links.Home) : router.push(Links.CreateAccount);
      return;
    }

    if (loginIntent === LoginIntent.individual) {
      if (loginResponse.isNewUser && userAccess.length === 1) {
        trackEvent({ event: SegmentTrackingId.RegistrationStarted });
        router.push(Links.CreateAccount);
      } else if (loginResponse.pepApprovalPending) {
        // If we get a pepDocument in the response we've not signed it so we go to IsPEP page
        if (loginResponse.pepDocument) {
          router.push(Links.IsPEP);
        }
        // Otherwise we've signed it already and go to PEPUser page
        else {
          router.push(Links.PEPUser);
        }
      } else if (loginResponse.isNewUser && userAccess.length > 1) {
        router.push(`${Links.ChooseAccount}?user=${loginIntent}&onboarding=false`);
      } else {
        // By default navigate to account selection page
        router.push(`${Links.ChooseAccount}?user=${loginIntent}&onboarding=false`);
      }
    } else {
      router.push(`${Links.ChooseAccount}?user=${loginIntent}&onboarding=false`);
    }
  };

  const checkLoginStatus = ({
    loginRequestToken,
    firstTime = 0,
    secondTime = 1000,
    finalPollingTime = new Date(),
  }: LoginStatusProps) => {
    const identifier = isLoggingInWithPhoneNumber ? phoneNumber : ssn;
    if (!identifier) return;

    if (finalPollingTime <= new Date()) {
      setLoginState(LoginStep.Error);
      setVerificationCode('');
      return;
    }

    loginRequestToken &&
      apiClient
        .getAudurWebApiClient()
        .putLoginStatus({
          identifier,
          loginRequestToken,
          platform: PlatformEnum.Web,
        })
        .then(async (response) => {
          const userAccess = await apiClient.getAudurWebApiClient().getUserAccess(false);

          if (userAccess.length !== 1) {
            return { response, userAccess };
          }

          setLocalStorageItem(LocalStorageKeys.USER_ACCESS, JSON.stringify(userAccess[0]));

          const isUserAccessMismatch = userAccess[0].ssn !== response.ssn && userAccess[0].isCurrent === false;

          if (isUserAccessMismatch) {
            try {
              const client = apiClient.getAudurWebApiClient();
              const switchUserAccessResponse = await client.switchUserAccess(response.ssn);
              setLocalStorageItem(LocalStorageKeys.USER_ACCESS, JSON.stringify(switchUserAccessResponse.access));
            } catch (error) {
              const isAlreadyActiveError =
                (error as AudurApiError)?.response?.data.code === ServerErrorCode.USERS_ACCESS_ALREADY_ACTIVE;
              if (!isAlreadyActiveError) {
                throw error;
              }
            }
          }

          return { response, userAccess };
        })
        .then(({ response, userAccess }) => {
          handleLoginSuccess(response, userAccess);
        })
        .catch((loginError: AudurApiError) => {
          if (loginError.response?.status === 412 || !loginError.response?.status) {
            const timeToWait = firstTime + secondTime;
            setTimeout(() => {
              checkLoginStatus({
                loginRequestToken,
                firstTime: secondTime,
                secondTime: timeToWait,
                finalPollingTime,
              });
            }, timeToWait);
          } else {
            onLoginFailed(loginError);
          }
        });
  };

  const onStartLoginSuccess = (response: KSAuthLoginInitResponseSchema) => {
    response.verificationCode && setVerificationCode(response.verificationCode);

    setTimeout(() => {
      const currentDate = new Date();
      const finalPollingTime = new Date(currentDate.getTime() + MAX_POLLING_TIME);
      checkLoginStatus({
        loginRequestToken: response.loginRequestToken,
        finalPollingTime,
      });
    }, 6000);
  };

  const onLoginFailed = (error: AudurApiError) => {
    setVerificationCode('');
    setLoginState(LoginStep.Error);
    errorHandling({
      dispatch,
      error,
      displayErrorToUser: true,
      sentryErrorString: SentryErrors.ResponseInvalid,
      analyticsData: { event: SegmentTrackingId.LoginFailed },
    });
  };

  const handleLogout = (company?: boolean) => {
    if (changingRouteStarted) return;
    apiClient
      .getAudurWebApiClient()
      .logout()
      .then(async () => {
        dispatch({ type: ActionType.LogoutUser });
        setChangingRouteStarted(true);
        router
          .push({
            pathname: Links.Login,
            query: { user: company ? 'company' : 'individual' },
          })
          .then(() => {
            setChangingRouteStarted(false);
          });
      })
      .catch(async (error: AudurApiError) => {
        if (error.response?.status === 401 || error.response?.status === 403) {
          dispatch({ type: ActionType.LogoutUser });
          setChangingRouteStarted(true);
          router
            .push({
              pathname: Links.Login,
              query: { user: company ? 'company' : 'individual' },
            })
            .then(() => {
              setChangingRouteStarted(false);
            });
        }
        errorHandling({
          dispatch,
          error,
          displayErrorToUser: true,
          sentryErrorString: SentryErrors.LogoutError,
          logoutUser: false,
        });
      });
  };

  const handleAsyncLogout = async (company?: boolean) => {
    if (changingRouteStarted) return;
    await apiClient
      .getAudurWebApiClient()
      .logout()
      .then(() => {
        dispatch({ type: ActionType.LogoutUser });
        setChangingRouteStarted(true);
        router
          .push({
            pathname: Links.Login,
            query: { user: company ? 'company' : 'individual' },
          })
          .then(() => {
            setChangingRouteStarted(false);
          });
      })
      .catch((error: AudurApiError) => {
        errorHandling({
          dispatch,
          error,
          displayErrorToUser: true,
          sentryErrorString: SentryErrors.LogoutError,
          logoutUser: false,
        });
      });
  };

  return {
    onLoginStart,
    loginState,
    verificationCode,
    setVerificationCode,
    handleLogout,
    handleAsyncLogout,
  };
};
