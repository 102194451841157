export enum Colors {
  BabyBlue = '#77C4D2',
  BabyBlueOpacity = 'rgba(119, 196, 210, 0.20)',
  Beige = '#F5F5F5',
  Black = '#000000',
  Blue = '#456EAB',
  CardGray = '#2F3747',
  CardGray2 = '#2A3241',
  DarkGray = '#252B39',
  Green = '#4CC97F',
  GreenOpacity = 'rgba(76, 201, 127, 0.20)',
  LightBlue = '#88C2D0',
  LightCardGray = '#374053',
  LightGray = '#AAADB3',
  CardAgencyGray = '#3F4551',
  LightOrange = '#F9855D',
  LightOrangeOpacity = 'rgba(249, 133, 93, 0.20)',
  NeutralBlack = '#151522',
  Orange = '#FF6E3C',
  Red = '#F06478',
  Transparent = '#FFFFFF00',
  White = '#ffffff',
  Yellow = '#F0D23C',
  YellowOpacity = 'rgba(240, 210, 60, 0.20)',
  GreenTM = '#2D6755',
  DarkBlue = '#252B39',
  BtnGreen = '#4CC97F',
  BtnGreenHover = '#70C68E',
  SilverDark = '#DBDBDB',
  SilverTransparent = 'rgba(245, 245, 245, 0.10)',
  Lila = '#EA98E3',
  LilaOpacity = 'rgba(187, 89, 239, 0.20)',
}

export enum FontSizePx {
  Small = '12px',
  BodySmall = '14px',
  Body = '16px',
  BodyLarge = '18px',
  H1 = '28px',
  H2 = '22px',
  Large = '28px',
  XL = '34px',
}

export enum FontSizeRem {
  Small = '0.75rem',
  BodySmall = '0.875rem',
  Body = '1rem',
  BodyLarge = '1.125rem',
  H1 = '1.75rem',
  H2 = '1.375rem',
  Large = '1.75rem',
  XL = '2.125rem',
}

export enum FontWeight {
  Default = '300',
  Inputs = '600',
}

export enum GridPx {
  Gridx4 = '4px',
  Gridx8 = '8px',
  Gridx16 = '16px',
  Gridx20 = '20px',
  Gridx24 = '24px',
  Gridx32 = '32px',
  Gridx40 = '40px',
  Gridx48 = '48px',
  Gridx56 = '56px',
  Gridx64 = '64px',
  Gridx72 = '72px',
  Gridx80 = '80px',
  Gridx88 = '88px',
  Gridx96 = '96px',
  Gridx104 = '104px',
  Gridx112 = '112px',
  Gridx120 = '120px',
  Gridx128 = '128px',
}

export enum GridRem {
  Gridx4 = '0.25rem',
  Gridx8 = '0.5rem',
  Gridx16 = '1rem',
  Gridx24 = '1.5rem',
  Gridx32 = '2rem',
  Gridx40 = '2.5rem',
  Gridx48 = '3rem',
  Gridx56 = '3.5rem',
  Gridx64 = '4rem',
  Gridx72 = '4.5rem',
  Gridx80 = '5rem',
  Gridx88 = '5.5rem',
  Gridx96 = '6rem',
  Gridx104 = '6.5rem',
  Gridx112 = '7rem',
  Gridx120 = '7.5rem',
  Gridx128 = '8rem',
}

export enum ScreenSizePx {
  Px320 = '320px',
  Px720 = '720px',
  Px1280 = '1280px',
}

export enum ScreenSizeEm {
  Px320 = '20em',
  Px480 = '30em',
  Px720 = '45em',
  Px1280 = '80em',
}

export type JustifyContent = 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between';
export type TooltipPlacement =
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';
