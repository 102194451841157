export const TranslatedStrings = {
  is: {
    footer: {
      audurPartOfKvika: 'Auður er vörumerki Kviku banka hf.',
      termsAndConditions: 'Skilmálar',
      frequentlyAskedQuestions: 'Algengar spurningar',
      contactUs: 'Hafa samband',
    },
    home: {
      savings: 'Sparnaður',
      transactions: 'Millifæra',
      calculator: 'Reiknivél',
      createAccount: 'Stofna reikning',
      calculatorTitle: 'Reikna áætlaða ávöxtun',
      companyCalculatorTooltip:
        'Hér má reikna út áætlaða ávöxtun innistæðu hjá Auði miðað við núverandi vaxtakjör. Vaxtakjör eru háð ákvörðun Auðar hverju sinni og taka mið af vaxtastigi á fjármálamarkaði.',
      calculatorTooltip:
        'Hér má reikna út áætlaða ávöxtun innstæðu hjá Auði að viðbættum áætluðum mánaðarlegum sparnaði miðað við núverandi vaxtakjör. Vaxtakjör eru háð ákvörðun Auðar hverju sinni og taka mið af vaxtastigi á fjármálamarkaði.',
      savingsTabTitle: 'Óbundinn reikningur',
      termTabTitle: 'Bundinn reikningur',
      futureTabTitle: 'Grænn framtíðarreikningur',
      close: 'Loka',
      createSavingsAccountInCalculator: 'Stofna óbundinn reikning',
      createTermAccountInCalculator: 'Stofna bundinn reikning',
      createFutureAccountInCalculator: 'Stofna framtíðarreikning',
      noTermAccountDescription:
        'Hentar þeim sem vilja fá enn betri vexti gegn því að binda sparnaðinn sinn í 3, 6 eða 12 mánuði.',
      noFutureAccountDescription:
        'Framtíðarreikningur Auðar er verðtryggður sparnaðarreikningur sem er bundinn til 18 ára aldurs.',
      maybeLater: 'Kannski seinna',
      graphCardTitle: 'Þetta helsta',
      totalBalance: 'Samanlagður sparnaður',
      totalInterestEarned: 'Áunnir ógreiddir vextir',
      futureAccountsBalance: 'Innstæða grænna framtíðarreikninga',
      totalInterestIncome12Months: 'Samtals vaxtatekjur síðustu 12 mánuði',
      savingsAccounts: { singular: 'Sparnaðar\u00ADreikning\u00ADur', plural: 'Sparnaðar\u00ADreikning\u00ADar' },
      termAccounts: { singular: 'Bundinn reikning\u00ADur', plural: 'Bundn\u00ADir reikning\u00ADar' },
      futureAccounts: {
        singular: 'Grænn framtíðar\u00ADreikning\u00ADur',
        plural: 'Græn\u00ADir framtíðar\u00ADreikning\u00ADar',
      },
      childrenAccounts: { singular: 'Reikning\u00ADur barn\u00ADs', plural: 'Reikning\u00ADar barn\u00ADa' },
      account: 'Reikningur',
      interestPercentage: 'Vaxtaprósenta',
      interestEarned: 'Áunnir vextir',
      balance: 'Staða',
      originalAccount: 'Sparnaðar\u00ADreikning\u00ADur',
      indexedAccounts: {
        singular: 'Verð\u00ADtryggð\u00ADur reikning\u00ADur',
        plural: 'Verð\u00ADtryggð\u00ADir reikning\u00ADar',
      },
      startSaving: 'Byrjaðu að spara',
      daysLeft: 'dagar eftir',
      inputRequired: 'Þetta svæði má ekki vera tómt',
    },
    transfer: {
      transfer: 'Millifæra',
      back: 'Til baka',
      forward: 'Áfram',
      close: 'Loka',
      chooseDestinationAccount: 'Veldu reikning til þess að leggja inn á',
      whichAccountToTransferTo: 'Inn á hvaða reikning vilt þú millifæra?',
      savingsAccounts: 'Óbundnir reikningar',
      futureAccounts: 'Grænir framtíðarreikningar',
      indexedAccounts: 'Verðtryggðir reikningar',
      disposableAccount: 'Ráðstöfunarreikningur',
      savingsShort: 'Sparnaðarreikningur',
      depositToAudur: 'Leggja inn á Auði',
      depositToAudurSubtitle:
        'Því meira sem þú leggur inn því meira getur þú tekið út. Eins og er átt þú enga lausa innstæðu til ráðstöfunar en þú getur alltaf lagt inn!',
      chooseSourceAccount: 'Veldu úttektarreikning',
      whichAccountToTransferFrom: 'Af hvaða reikning vilt þú millifæra?',
      chooseAmount: 'Veldu upphæð',
      sourceAccount: 'Úttektarreikningur:',
      transferredTo: 'Lagt inn á:',
      amount: 'Upphæð',
      accountStatus: 'Staða',
      availableAmountToUse: 'Til ráðstöfunar',
      toBeTransferred: 'Verða millifærðar á eftirfarandi reikning eftir 90 daga',
      transferSuccessful: 'Voru millifærðar á eftirfarandi reikning',
      deposit: 'Leggja inn',
    },
    transactions: {
      print: 'Sækja yfirlit',
      downloadExcel: 'Sækja Excel skrá',
      rename: 'Endurnefna',
      delete: 'Loka reikning',
      noTransactions: 'Engin viðskipti',
      printTransactionsSummary: 'Prenta færsluyfirlit',
      printSummary: 'Prenta yfirlit',
      cancel: 'Hætta við',
      selectLanguage: 'Veldu tungumál',
      renameAccount: 'Endurnefna reikning',
      renameAccountDesc: 'Gefðu reikningnum eitthvað frábært nafn sem lýsir honum vel.',
      nameOfAccountInvalid: 'Nafn á reikning er ógilt',
      renameAccountPlaceholder: 'Sláðu inn nýtt reikningsnafn',
      accountName: 'Nafn á reikning',
      deleteAccount: 'Eyða reikning',
      deleteDescriptionP1:
        'Reikningnum verður eytt og innstæða ásamt áunnum vöxtum, að frádregnum fjármagnstekjuskatti, verður lögð inn á Sparnaðarreikninginn þinn.',
      deleteDescriptionP2: 'Ekki er hægt að taka þessa aðgerð til baka.',
      deleteTimeInfo: 'Athugaðu að það getur tekið smástund að ganga frá þessu',
      depositAccount: 'Innistæðan verður lögð inn á:',
    },
    settings: {
      settings: 'Stillingar',
      cancel: 'Hætta við',
      notEmail: 'Þetta er ekki netfang',
      correctEmail: 'Netfangið er rétt',
      correctBankAccount: 'Reikningur stemmir við kennitölu',
      incorrectBankAccount: 'Reikningurinn samsvarar ekki kennitölu',
      switchAccount: 'Skipta um notanda',
      validAccount: 'Reikningur stemmir við kennitölu',
      notValidAccount: 'Reikningurinn samsvarar ekki kennitölu',
      email: 'Netfang',
      externalAccount: 'Ráðstöfunarreikningur',
      childrenAccounts: 'Reikningar barna',
      showChildrenAccounts: 'Sýna reikninga þeirra barna sem eru á þínu forræði.',
      editExternalAccount: 'Sláðu inn ytri reikning',
      editEmail: 'Sláðu inn netfang',
      closedAccounts: 'Lokaðir reikningar',
    },
    createAccounts: {
      createNewAccount: 'Stofna nýjan reikning',
      openCalculator: 'Opna reiknivél',
      interestRate: 'Vextir',
      createSavingsAccount: 'Stofna óbundinn reikning',
      createTermAccount: 'Stofna bundinn reikning',
      createFutureAccount: 'Stofna framtíðarreikning',
      showMore: 'Nánar',
      showLess: 'Sýna minna',
    },
    general: {
      cancelOnboardingTitle: 'Hætta við skráningu í Auði?',
      cancelOnboardingDescription: 'Svörin þín munu þurrkast út en þú getur alltaf byrjað upp á nýtt þegar þér hentar.',
      cancelOnboardingButton: 'Hætta skráningu',
      continueOnboarding: 'Halda áfram skráningu',
      cancel: 'Hætta við',
      moment: 'Augnablik',
      year: 'Ár',
      selectYear: 'Veldu ár',
      icelandic: 'Íslenska',
      english: 'Enska',
      confirm: 'Staðfesta',
      from: 'Frá',
      to: 'Til',
      chooseDate: 'Veldu dagsetningu',
    },
    announcements: {
      multipleUnrestrictedAccounts: {
        subject: 'Búðu til marga óbundna reikninga!',
        description:
          'Gleðitíðindi! Fyrirtækjaviðskiptavinir Auðar geta nú búið til marga óbundna reikninga! Hægt er að stofna eins marga og hver og einn vill ásamt því að nefna þá eftir því sem við á. Til dæmis ef verið er að safna fyrir virðisaukaskattinum og fleira! \n\n Engar áhyggjur, reikningarnir bera að sjálfsögðu frábæru vexti Auðar. \n\n Gleðilegan sparnað.\n\n',
      },
    },
  },
};
