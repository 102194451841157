import Button, { ButtonVariantUnion } from '@kvika/audur-button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalSize } from '@kvika/audur-modal';
import Lottie from 'lottie-react';
import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { GeneralStrings } from 'utils/strings';
import { ButtonSize } from '@kvika/audur-button/build/es/Constants';
import Shapes from '../../../public/lottie/loader_shapes.json';
import FontWrapper from '../../styles/FontWrapper';
import AudurTooltip from '../Tooltip/AudurTooltip';

type Props = {
  isOpen: boolean;
  title: string;
  handleClose?(): void;
  children: ReactNode;
  continueText?: string;
  onContinue?(): void;
  backText?: string;
  onBack?(): void;
  size?: ModalSize;
  isLoading?: boolean;
  showCloseButton?: boolean;
  closeOnOutsideClick?: boolean;
  scrollable?: boolean;
  className?: string;
  showLoader?: boolean;
  tooltipText?: string;
  backButtonVariant?: ButtonVariantUnion;
  continueButtonVariant?: ButtonVariantUnion;
  buttonsSize?: ButtonSize;
  continueButtonDisabled?: boolean;
};

const AudurModal = ({
  isOpen,
  title,
  handleClose,
  children,
  size = ModalSize.SHORT,
  continueText: callToActionText = GeneralStrings.Forward,
  onContinue: onCallToAction,
  backText = GeneralStrings.Back,
  onBack,
  isLoading,
  showCloseButton = true,
  closeOnOutsideClick = true,
  scrollable = false,
  className,
  showLoader = false,
  tooltipText,
  backButtonVariant = 'outlined',
  continueButtonVariant = 'outlined',
  buttonsSize = 'medium',
  continueButtonDisabled = false,
}: Props) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  if (typeof window === 'undefined') return null; // We need to make sure that window is defined before rendering the modal since the modal uses event listeners which depend on window being defined. If this is removed, all pages with a modal will break when trying to SSR

  const showFooter = !!(onCallToAction || onBack);

  return (
    <Modal
      isVisible={isOpen}
      onClose={handleClose}
      size={size}
      closeOnClickOutside={closeOnOutsideClick}
      className={className}
    >
      {title && (
        <ModalHeader showCloseButton={showCloseButton}>
          <TitleWrapper>
            <FontWrapper>{title}</FontWrapper>
            {tooltipText && <AudurTooltip id="calculator-tooltip" text={tooltipText} />}
          </TitleWrapper>
        </ModalHeader>
      )}

      <StyledModalBody scrollable={scrollable} {...(!title && { showCloseButton })}>
        <ModalChildrenWrapper>
          <FontWrapper>{children}</FontWrapper>
        </ModalChildrenWrapper>
      </StyledModalBody>
      {showFooter && (
        <ModalFooter>
          <Button onClick={onBack} variant={backButtonVariant} size={buttonsSize} disabled={isLoading}>
            {backText}
          </Button>
          <Button
            onClick={onCallToAction}
            variant={continueButtonVariant}
            loading={isLoading}
            size={buttonsSize}
            disabled={continueButtonDisabled}
            loadingLottiePath="/lottie/loader_shapes.json"
          >
            {callToActionText}
          </Button>
        </ModalFooter>
      )}
      {showLoader && <StyledLottie animationData={Shapes} loop />}
    </Modal>
  );
};

const ModalChildrenWrapper = styled.div`
  overflow: auto;
`;

const StyledLottie = styled(Lottie)`
  max-width: 187px;
  align-self: center;
`;

const StyledModalBody = styled(ModalBody)`
  & > div {
    width: 100% !important;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export default AudurModal;
