const SpreadsheetFile = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="file-spreadsheet">
      <path
        id="Vector"
        d="M5.25 21V3C5.25 2.5875 5.5875 2.25 6 2.25H13.5V6C13.5 6.82969 14.1703 7.5 15 7.5H18.75V21C18.75 21.4125 18.4125 21.75 18 21.75H6C5.5875 21.75 5.25 21.4125 5.25 21ZM6 0C4.34531 0 3 1.34531 3 3V21C3 22.6547 4.34531 24 6 24H18C19.6547 24 21 22.6547 21 21V7.24219C21 6.44531 20.6859 5.68125 20.1234 5.11875L15.8766 0.876563C15.3141 0.314063 14.5547 0 13.7578 0H6ZM11.25 12V14.25H8.25V12H11.25ZM8.25 15.75H11.25V18H8.25V15.75ZM12.75 15.75H15.75V18H12.75V15.75ZM12 19.5H12.75H15.75C16.5797 19.5 17.25 18.8297 17.25 18V15.75V15V14.25V12C17.25 11.1703 16.5797 10.5 15.75 10.5H12.75H12H11.25H8.25C7.42031 10.5 6.75 11.1703 6.75 12V14.25V15V15.75V18C6.75 18.8297 7.42031 19.5 8.25 19.5H11.25H12ZM12.75 14.25V12H15.75V14.25H12.75Z"
        fill="#F5F5F5"
      />
    </g>
  </svg>
);
export default SpreadsheetFile;
