import { Colors } from 'styles/Styleguide';

type Props = {
  isSelected?: boolean;
  selectedColor?: string;
  className?: string;
};
const CheckboxCircle = ({ isSelected = false, selectedColor = Colors.Green, className }: Props) => {
  if (isSelected)
    return (
      <svg
        className={className}
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Checkbox">
          <rect id="box" width="22" height="22" rx="11" fill={selectedColor} />
          <circle id="Ellipse 1" cx="11" cy="11" r="4" fill="#F5F5F5" />
        </g>
      </svg>
    );
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="20" height="20" rx="10" stroke="#F5F5F5" strokeWidth="2" />
    </svg>
  );
};
export default CheckboxCircle;
