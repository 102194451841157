const ExportFile = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Export file">
      <path
        id="Vector"
        d="M5.25 21V3C5.25 2.5875 5.5875 2.25 6 2.25H13.5V6C13.5 6.82969 14.1703 7.5 15 7.5H18.75V21C18.75 21.4125 18.4125 21.75 18 21.75H6C5.5875 21.75 5.25 21.4125 5.25 21ZM6 0C4.34531 0 3 1.34531 3 3V21C3 22.6547 4.34531 24 6 24H18C19.6547 24 21 22.6547 21 21V7.24219C21 6.44531 20.6859 5.68125 20.1234 5.11875L15.8766 0.876563C15.3141 0.314063 14.5547 0 13.7578 0H6ZM13.125 10.875C13.125 10.2516 12.6234 9.75 12 9.75C11.3766 9.75 10.875 10.2516 10.875 10.875V15.6609L9.42188 14.2078C8.98125 13.7672 8.26875 13.7672 7.83281 14.2078C7.39687 14.6484 7.39219 15.3609 7.83281 15.7969L11.2078 19.1719C11.6484 19.6125 12.3609 19.6125 12.7969 19.1719L16.1719 15.7969C16.6125 15.3562 16.6125 14.6438 16.1719 14.2078C15.7312 13.7719 15.0188 13.7672 14.5828 14.2078L13.1297 15.6609L13.125 10.875Z"
        fill="#F5F5F5"
      />
    </g>
  </svg>
);
export default ExportFile;
