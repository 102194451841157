const Calendar = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="calendar-dates">
      <path
        id="Vector"
        d="M8.125 1.125C8.125 0.501562 7.62344 0 7 0C6.37656 0 5.875 0.501562 5.875 1.125V3H4C2.34531 3 1 4.34531 1 6V6.75V9V21C1 22.6547 2.34531 24 4 24H19C20.6547 24 22 22.6547 22 21V9V6.75V6C22 4.34531 20.6547 3 19 3H17.125V1.125C17.125 0.501562 16.6234 0 16 0C15.3766 0 14.875 0.501562 14.875 1.125V3H8.125V1.125ZM3.25 9H19.75V21C19.75 21.4125 19.4125 21.75 19 21.75H4C3.5875 21.75 3.25 21.4125 3.25 21V9Z"
        fill="#AAADB3"
      />
    </g>
  </svg>
);
export default Calendar;
